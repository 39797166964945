import { LocalStorageKeys } from '@/constants'
import { PermissionEnum } from '@/enums'
import { IStore } from '@/store/types'
import Storage from '../localStorage'

export const getLocalGlobalState = () => {
  return Storage.get<IStore>(LocalStorageKeys.GLOBAL_STATE)
}
// NOTE: getUserInfo使用场景为从本地获取用户信息,而本地的用户信息来自vuex watch到用户信息变更的时候存入本地, 这个存入是异步的, 所以在用户信息变更时直接同步获取用户信息会拿到旧值, 暂时没有想到好办法解决
export const getUserInfo = () => {
  const userInfo = getLocalGlobalState()?.user.userInfo
  if (!userInfo) return null
  return {
    role: userInfo.userLevel,
    nickname: userInfo.firmName,
    userId: userInfo.firmUserId,
    authList: userInfo.authList ? userInfo.authList.map((item) => item.toString() as PermissionEnum) : [],
  }
}
