import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { App } from 'vue'
import { createRouterGuards } from './router-guards'
import { RouterTransition } from '@/components/transition'
import { PermissionEnum, RoleEnum } from '@/enums'
import { ThirdPartyRouterView } from '@/components/common/router'
export * from './utils'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    redirect: {
      name: 'Home',
    },
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页',
          icon: 'icon-shouye-weixuanzhong',
          hideBreadcrumb: true,
        },
      },
      {
        path: '/notice/:page?',
        name: 'Notice',
        component: () => import('@/views/notice/index.vue'),
        meta: {
          title: '通知公告',
          icon: 'icon-tongzhigonggao-weixuanzhong',
          // auth: {
          //   permissions: [PermissionEnum.通知公告],
          // },
        },
      },
      {
        path: '/case',
        name: 'Case',
        component: RouterTransition,
        meta: {
          title: '案件管理',
          icon: 'icon-anjianguanli-weixuanzhong',
        },
        redirect: {
          name: 'CaseIndex',
        },
        children: [
          {
            path: '/case/index/:type?',
            name: 'CaseIndex',
            component: () => import('@/views/case/index.vue'),
            meta: {
              title: '案件管理',
              auth: {
                permissions: [PermissionEnum.案件管理],
              },
            },
          },
        ],
      },
      {
        path: '/customer',
        name: 'Customer',
        component: RouterTransition,
        meta: {
          title: '客户管理',
          icon: 'icon-kehuguanli-weixuanzhong',
        },
        redirect: {
          name: 'CustomerIndex',
        },
        children: [
          {
            path: '/customer/index',
            name: 'CustomerIndex',
            component: () => import('@/views/customer/index.vue'),
            meta: {
              title: '客户管理',
              // auth: {
              //   permissions: [PermissionEnum['客户管理.查看']],
              // },
            },
          },
        ],
      },
      {
        path: '/vertical',
        name: 'Vertical',
        component: RouterTransition,
        meta: {
          title: '利冲检索',
          icon: 'icon-lichongjiansuo-weixuanzhong',
        },
        redirect: {
          name: 'VerticalIndex',
        },
        children: [
          {
            path: '/vertical/index/:type?',
            name: 'VerticalIndex',
            component: () => import('@/views/vertical/index.vue'),
            meta: {
              title: '利冲检索',
              // auth: {
              //   permissions: [PermissionEnum.利冲检索],
              // },
            },
          },
        ],
      },
      {
        path: '/contract',
        name: 'Contract',
        component: RouterTransition,
        meta: {
          title: '合同管理',
          icon: 'icon-hetongguanli-weixuanzhong',
        },
        redirect: {
          name: 'ContractIndex',
        },
        children: [
          {
            path: '/contract/index',
            name: 'ContractIndex',
            component: () => import('@/views/contract/index.vue'),
            meta: {
              title: '合同管理',
              auth: {
                permissions: [PermissionEnum.合同管理],
              },
            },
          },
        ],
      },
      {
        path: '/seal',
        name: 'Seal',
        component: RouterTransition,
        meta: {
          title: '用章管理',
          icon: 'icon-yongzhangguanli-weixuanzhong',
        },
        redirect: {
          name: 'SealIndex',
        },
        children: [
          {
            path: '/seal/index',
            name: 'SealIndex',
            component: () => import('@/views/seal/index.vue'),
            meta: {
              title: '用章管理',
              auth: {
                permissions: [PermissionEnum.用章管理],
              },
            },
          },
        ],
      },
      {
        path: '/finance',
        name: 'Finance',
        component: RouterTransition,
        meta: {
          title: '财务管理',
          icon: 'icon-caiwuguanli-weixuanzhong',
        },
        redirect: {
          name: 'FinanceIndex',
        },
        children: [
          {
            path: '/finance/index',
            name: 'FinanceIndex',
            component: () => import('@/views/finance/index.vue'),
            meta: {
              title: '财务管理',
              auth: {
                permissions: [PermissionEnum.财务管理],
              },
            },
          },
        ],
      },
      {
        path: '/approve',
        name: 'Approve',
        component: RouterTransition,
        meta: {
          title: '审批管理',
          icon: 'icon-shenpiguanli-weixuanzhong',
        },
        redirect: {
          name: 'ApproveIndex',
        },
        children: [
          {
            path: '/approve/index',
            name: 'ApproveIndex',
            component: () => import('@/views/approve/index.vue'),
            meta: {
              title: '审批管理',
              auth: {
                permissions: [PermissionEnum.审批管理],
              },
            },
          },
        ],
      },

      {
        path: `/business-card`,
        name: 'BusinessCard',
        component: () => import('@/views/business-card/index.vue'),
        meta: {
          title: '个人名片',
          icon: 'icon-gerenmingpian-weixuanzhong',
          // auth: {
          //   permissions: [PermissionEnum.个人名片],
          // },
        },
      },
      {
        path: '/phone',
        name: 'Phone',
        component: () => import('@/views/phone/index.vue'),
        meta: {
          title: '行业通讯录',
          icon: 'icon-hangyetongxunlu-weixuanzhong',
          auth: {
            permissions: [PermissionEnum['行业通讯录']],
          },
        },
      },

      {
        path: '/system',
        name: 'System',
        component: RouterTransition,
        redirect: {
          name: 'Lawyer',
        },
        meta: {
          title: '系统设置',
          icon: 'icon-xitongshezhi-weixuanzhong',
          auth: [RoleEnum.admin],
        },
        children: [
          {
            path: '/lawyer',
            name: 'Lawyer',
            component: () => import('@/views/system/lawyer.vue'),
            meta: {
              title: '用户设置',
              roles: [RoleEnum.admin],
              auth: {
                permissions: [PermissionEnum.用户设置],
              },
            },
          },
          {
            path: '/approve-Config',
            name: 'ApproveConfig',
            component: () => import('@/views/system/approveConfig.vue'),
            meta: {
              title: '审批设置',
              roles: [RoleEnum.admin],
              auth: {
                permissions: [PermissionEnum.审批设置],
              },
            },
          },
        ],
      },
      {
        path: '/exception/:code',
        name: 'Exception',
        meta: {
          title: '403 ',
          hideBreadcrumb: true,
          hideInMenu: true,
        },
        component: () => import('@/views/exception/index.vue'),
      },
      {
        path: '/:path(.*)*',
        meta: {
          title: '404 ',
          hideBreadcrumb: true,
          hideInMenu: true,
        },
        redirect: {
          name: 'Exception',
          params: { code: 404 },
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  },
  {
    path: '/province-association',
    name: 'ProvinceAssociation',
    meta: {
      title: '访问律协数据平台',
      icon: 'icon-fangwenhunan-weixuanzhong',
      hideInMenu: true,
    },
    component: ThirdPartyRouterView,
    redirect: {
      name: 'ProvinceAssociationDataCenter',
    },
    children: [
      {
        path: '/province-association/:page?',
        component: () => import('@/views/province/index.vue'),
        name: 'ProvinceAssociationDataCenter',
        meta: {
          title: '数据报送中心',
          auth: {
            permissions: [PermissionEnum['访问律协数据平台.数据报送中心']],
          },
        },
      },
      {
        path: 'contribution',
        name: 'Contribution',
        component: () => import('@/views/province/Contribution.vue'),
        meta: {
          title: '新闻投稿',
          auth: {
            permissions: [PermissionEnum['访问律协数据平台.新闻投稿']],
          },
        },
      },
      {
        path: 'questionnaire',
        name: 'Questionnaire',
        component: () => import('@/views/province/Questionnaire.vue'),
        meta: {
          title: '问卷调查',
          auth: {
            permissions: [PermissionEnum['访问律协数据平台.问卷调查']],
          },
        },
      },
    ],
  },
  {
    path: '/delegate',
    meta: {
      title: '异地查档协助中心',
      icon: 'icon-gerenmingpian-weixuanzhong1',
      auth: {
        permissions: [PermissionEnum.委托广场],
      },
      hideInMenu: true,
    },
    component: ThirdPartyRouterView,
    name: 'Delegate',
    children: [
      {
        path: '',
        name: 'DelegateSquare',
        meta: { title: '委托广场' },
        component: () => import('@/views/delegate/index.vue'),
        children: [],
      },
      {
        path: 'published',
        name: 'PublishedDelegate',
        meta: { title: '我发布的委托' },
        component: () => import('@/views/delegate/Published.vue'),
        children: [],
      },
      {
        path: 'received',
        name: 'AppliedDelegate',
        meta: { title: '我帮助的委托' },
        component: () => import('@/views/delegate/Applied.vue'),
        children: [],
      },
      {
        path: 'my-info',
        name: 'DelegateMyInfo',
        meta: { title: '我的信息', hidden: true },
        component: () => import('@/views/delegate/MyInfo.vue'),
        children: [],
      },
    ],
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
export function setupRouter(app: App) {
  app.use(router)
  // 创建路由守卫
  createRouterGuards(router)
}
export default router
