/**
 * @description 案件类型
 */
export enum CaseScopeEnum {
  CIVIL_ACTION = 10, // 民事诉讼
  CRIMINAL_ACTION = 20, // 刑事诉讼
  ADMINISTRATIVE_BUSINESS = 30, // 行政业务
  SPECIAL_BUSINESS = 40, // 专项业务
  LEGAL_ADVISORY_BUSINESS = 50, // 法律顾问业务
  OTHER_BUSINESS = 60 // 专项业务
}
