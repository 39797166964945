/**
 * 案件状态
 */
export const CaseProcess = {
  1: '新建案件',
  2: '办理中',
  3: '已归档'
};

/**
 * 案件类型的图标
 */
export const CaseTypeIcon = {
  10: 'icon-minshisusong',
  20: 'icon-xingshianjian',
  30: 'icon-hangzhengyewu',
  40: 'icon-zhuanxiangyewu',
  50: 'icon-falvguwenyewu',
  60: 'icon-qitayewu'
};
