// 采集状态
export enum CollectionStatus {
  gathering = 1,//采集中
  notGather,//未采集
}

export type DataCollection = {
  /**
   * 报表类型
   */
  reportType?: number
  /**
   * 标题
   */
  title?: string
  /**
   * 提交截止时间
   */
  endTime?: string
  /**
   * 是否实采集中 1：是 2：否
   */
  collectionStatus?: CollectionStatus,
  //  *是否实采集中 1：是 2：否
  sendState?: CollectionStatus,

}
