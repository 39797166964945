export const HELP_URL_MAP = {
  loginSetting: {
    title: '登录和用户设置',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/loginSetting.mp4',
  },
  seal: {
    title: '用章管理',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/useSeal.mp4',
  },
  phone: {
    title: '行业通讯录',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/mailList.mp4',
  },
  finance: {
    title: '财务管理',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/finance.mp4',
  },
  customer: {
    title: '客户管理',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/customer.mp4',
  },
  contract: {
    title: '合同管理',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/contract.mp4',
  },
  case: {
    title: '案件管理',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/case.mp4',
  },
  card: {
    title: '名片管理',
    url: 'https://lawyer-association-dev.oss-cn-beijing.aliyuncs.com/ls/card.mp4',
  },
} as const
