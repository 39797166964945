<template>
  <div class="wrapper">
    <SuspenseWithError>
      <router-view v-slot="{ Component }">
        <transition name="fade-slide" mode="out-in" appear>
          <component :is="Component" />
        </transition>
      </router-view>
    </SuspenseWithError>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="less" scoped>
.wrapper {
  padding: 16px;
  background-color: #f8f9fc;
  min-height: 100vh;
  overflow-x: hidden;
}
</style>
