import { CustomerModel } from '@/types/customer'
import { LawyerOptionsModel } from '@/types/lawyer'
import { ProjectScopeModel } from '@/types/dictionary'
export * from './router'
export { reportObject } from './report'
export { eleFormCheckPhone, timeFormat, checkPhone } from './validate'
export * from './file'
export * from './has'
export * from './get'
export * from './tools'
export * from './localStorage'
/**
 * @description 前端存储
 */

/**
 * @description 获取以“、”隔开的客户姓名字符串
 * @param customers 客户数组
 * @returns
 */
export const getCustomerName = (customers: CustomerModel[] | undefined) => {
  let customerName = [] as string[]
  if (!customers?.length) return
  customerName = customers.map((customer) => customer.customerName)
  return customerName.join('、')
}

/**
 * @description 将对方当事人数组形式转换成“、”隔开的字符串
 * @param opposites
 * @returns
 */
export const getOppositeName = (opposites: string[] | undefined) => {
  if (!opposites?.length) return
  //
  return opposites.join('、')
}

/**
 * @description 将案源律师数组形式转换成“、”隔开的字符串
 * @param lawyers
 * @returns
 */
export const getLawyereName = (lawyers: LawyerOptionsModel[] | undefined) => {
  let lawyerName = [] as string[]
  if (!lawyers?.length) return
  lawyerName = lawyers.map((lawyer) => lawyer.username)
  return lawyerName.join('、')
}

/**
 * @description 判断是案件还是业务
 * 审理阶段、委托人身份是否显示：民事、行政、刑事展示
 */
export const isCase = (projectScopeOptions: ProjectScopeModel[], scopeId: number | undefined) => {
  const options = [...projectScopeOptions]
  const newOptions = options.splice(0, options.length - 3)
  const valid = newOptions.some((item) => item.businessScopeId === scopeId)
  return valid
}

/**
 * @description 将文件转换成以”、“隔开的字符串
 * @param fileList 文件列表
 */
export const getFileName = (fileList) => {
  let fileName = [] as string[]
  if (!fileList?.length) return
  fileName = fileList.map((file) => file.fileName)
  return fileName.join('、')
}
/**
 * @description 重置form
 * @param formRef form实例
 */
export const resetForm = (formRef: any) => {
  formRef.resetFields()
}
