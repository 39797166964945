import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { App, InjectionKey } from 'vue'
import modules from '@/store/modules'
import { Storage } from '@/utils'
import { LocalStorageKeys } from '@/constants'
import { IStore } from './types'
export * from './constant'
const key: InjectionKey<Store<any>> = Symbol()

const store = createStore({
  modules,
})

// 定义你自己的“useStore”组合函数
export function useStore() {
  return baseUseStore<IStore>(key)
}

export function setupStore(app: App) {
  app.use(store, key)
}
// TODO: 此处应加入忽略机制,并非所有全局状态都需要缓存至本地, 而且此处watch是异步的, 会导致修改后立即从本地获取状态拿到的是确是旧值, 暂时没想到好办法解决
store.watch(
  (state) => {
  console.log("🚀 ~ state", state)
    Storage.set(LocalStorageKeys.GLOBAL_STATE, state)
  },
  () => {},
)
