/**
 * @description 数据页面类型
 */
export enum ProvincePageEnum {
  PAGE_REPORT_TYPE = 'report-type', // 表权限列表
  PAGE_REPORT_TIME_LIST = 'time-list', // 表时间列表
  PAGE_REPORT_EDIT = 'edit', // 编辑页
  PAGE_REPORT_LOOK = 'look' // 查看表（数据采集）
}

/**
 * @description 发布对象
 */
export enum SendObject {
  OBJECT_ASSOCIATION = 1, // 全省地市律协
  OBJECT_FIRM = 2 // 全省律所
}

// 发布对象
export enum PublishObject {
  LAW_ASSOCIATION = 1,//地市律协
  LAW_FIRM//全省律所
}

export const PublishObjectMap = {
  [PublishObject.LAW_ASSOCIATION]: '地市律协',
  [PublishObject.LAW_FIRM]: '全省律所'
}
export enum CollectionStatusEnum {
  DOING = 1, // 采集中
  END = 2 // 采集结束
}
export const CollectionStatusEnumMap = {
  [CollectionStatusEnum.DOING]: { desc: '采集中', color: '#f79160' },
  [CollectionStatusEnum.END]: { desc: '采集结束', color: '#5BA6FF' }
}
/**
 * @description 表格类型
 */
export enum ReportTypeEnum {
  REPORT_ELEVEN = 11,
  REPORT_TWELVE = 12,
  REPORT_THIRTEEN = 13,
  REPORT_FOURTEEN = 14
}
