import { INoticeState } from './state';

export const mutations = {
  /**
   * @description 设置通知列表
   * @param state
   * @param noticeList
   */
  setNoticeMutation: (state: INoticeState, data) => {
    state.noticeList = data.records;
    state.total = Number(data.total);
  }
};
