import { moneyReg, phoneReg, pwdReg, telReg } from '@/constants/regexp'

/**
 * 验证手机
 * @param phone
 * @returns boolean true代表通过验证
 */
export const verifyPhone = (phone: string): boolean => {
  return phoneReg.test(phone)
}

/**
 * 验证400电话
 * @param tel
 * @return booean
 */
export const verifyTel = (tel: string): boolean => {
  return telReg.test(tel)
}

/**
 * 自定义element-UI的表单验证规则 手机号
 * @param rule
 * @param value
 * @param callback
 */
export const eleFormCheckPhone = (rule: any, value: string) => {
  if (!value) {
    return Promise.reject('请输入手机号码')
  } else {
    if (phoneReg.test(value)) {
      return Promise.resolve()
    } else {
      return Promise.reject('请输入正确的电话号码')
    }
  }
}

export const timeFormat = (rule: any, value: any) => {
  const valueArr = value.split('-')
  const start = valueArr[0] - 0
  const end = valueArr[1] - 0

  // if (!timeReg.test(value)) {
  //   return Promise.reject('请填入正确的时间格式(如2015-2020)');
  // }

  // if (start > end) {
  //   return Promise.reject('结束时间不能小于开始时间')
  // }

  return Promise.resolve()
}

/**
 * 金额通用验证函数
 * @param rule
 * @param value
 * @param callback
 */
export const eleFormCheckMoney = (rule: any, value: string, callback: any) => {
  if (!value) {
    callback()
  } else {
    if (moneyReg.test(value)) {
      callback()
    } else if (/[^\d|^.]/.test(value)) {
      return callback(new Error('请输入正确的金额'))
    } else {
      return callback(new Error('精确到小数点后两位'))
    }
  }
}

/**
 * 微信支付金额验证函数
 * @param rule
 * @param value
 * @param callback
 */
export const eleFormCheckWeixinMoney = (rule: any, value: string, callback: any) => {
  if (!value) {
    callback()
  } else {
    if (/[^\d|^.]/.test(value)) {
      return callback(new Error('请输入正确的金额'))
    } else if (Number(value) > 20000) {
      return callback(new Error('支付上限为20,000.00'))
    } else if (moneyReg.test(value)) {
      callback()
    } else {
      return callback(new Error('精确到小数点后两位'))
    }
  }
}

export const eleFormCheckPwd = (rule: any, value: string, callback: any, data: any) => {
  if (!value) {
    callback()
  } else {
    if (pwdReg.test(value)) {
      callback()
    } else {
      return callback(new Error('密码限制6-15个字符，至少包含一个数字和一个字母'))
    }
  }
}

export const checkPhone = (val) => {
  const reg = /^(1)[0-9]{10}$/
  return reg.test(val)
}
