import { DictionaryState } from './state'
import { ProjectScopeModel, ProjectReasonModel, TrialStageModel, ClientModel } from '@/types/dictionary'
import { Item } from 'ant-design-vue/lib/menu'

export const mutations = {
  // setToken: (state: UserState, token: string) => {
  //   state.token = token;
  // },
  setProjectScope: (state: DictionaryState, projectScope: Array<ProjectScopeModel>) => {
    const projectScopeMap = {} as any
    if (projectScope.length) {
      projectScope.map((item) => {
        projectScopeMap[item.businessScopeId] = item.businessScopeName
      })
    }
    state.projectScopeOptions = projectScope
    state.projectScopeMap = projectScopeMap
  },
  setProjectReason: (state: DictionaryState, projectReason: Array<ProjectReasonModel>) => {
    projectReason.forEach((project) => {
      project.label = project.businessTypeName
      project.value = project.businessTypeId
      project.reasonList?.forEach((reason) => {
        reason.value = reason.businessDescId
        reason.label = reason.businessDescName
      })
    })
    state.projectReasonOptions = projectReason
  },
  setTrialStage: (state: DictionaryState, trialStage: Array<TrialStageModel>) => {
    state.trialStageOptions = trialStage
  },
  setClient: (state: DictionaryState, client: Array<ClientModel>) => {
    state.clientOptions = client
  },
  // setRoles: (state: DictionaryState, roles) => {
  //   state.roles = roles;
  // }
  // setUserInfo: (state: UserState, info) => {
  //   state.info = info;
  // }
}
