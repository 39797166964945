import { DictionaryState } from './state'

export const getters = {
  // token: (state: UserState) => state.token,
  projectScopeOptions: (state: DictionaryState) => state.projectScopeOptions,
  projectReasonOptions: (state: DictionaryState) => state.projectReasonOptions,
  trialStageOptions: (state: DictionaryState) => state.trialStageOptions,
  clientOptions: (state: DictionaryState) => state.clientOptions,
  projectScopeMap: (state: DictionaryState) => state.projectScopeMap,

  // userInfo: (state: UserState) => state.info
}
