import { http } from '@/service';
import { FIRM_PREFIX_URL, COMMON_API_PREFIX } from '@/constants';

/**
 * @description: 3107001 获取业务范围信息
 */
export const getProjectScope = (): Promise<any> => {
  return http.get(`${FIRM_PREFIX_URL}/dic/getProjectScope`);
};

/**
 * @description: 3107002 获取案由信息
 * @param scope 业务范围Id
 */
export const getProjectReason = (scope: string): Promise<any> => {
  return http.get(`${FIRM_PREFIX_URL}/dic/getProjectReason/${scope}`);
};

/**
 * @description: 3107003 获取阶段信息
 * @param typeId 业务类型Id
 */
export const getTrialStage = (typeId: string): Promise<any> => {
  return http.get(`${FIRM_PREFIX_URL}/dic/getTrialStage/${typeId}`);
};

/**
 * @description: 3107004 获取委托方身份
 * @param trialStageId 审理阶段Id
 */
export const getPrincipalIdentity = (trialStageId: string): Promise<any> => {
  return http.get(`${FIRM_PREFIX_URL}/dic/getPrincipalIdentity/${trialStageId}`);
};

/**
 * @description: 4102001 获取行政区划列表
 * @param code 所属代码（省或市）
 * @param type 请求类型 1：省 2：地级市，3：区县
 */
export const getXZQHList = (type: number, code?: number): Promise<any> => {
  return http.post(`${COMMON_API_PREFIX}/dictionary/getXzqhList`, { type, code });
};
