import { NoticeModel } from '@/types';

export type INoticeState = {
  noticeList: Array<NoticeModel>; // 通知列表
  total: number;
};

export const state: INoticeState = {
  noticeList: [],
  total: -1
};
