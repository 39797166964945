import { PermissionEnum } from '@/enums'

export type CardType = { icon: string; title: string; name: string; auth?: AuthType }
/**
 * 常用功能
 */
export const CommonOptions: CardType[] = [
  {
    icon: 'icon-xinzenganjian1',
    title: '新建案件',
    name: 'Case',
    auth: { permissions: [PermissionEnum.案件管理] },
  },
  {
    icon: 'icon-xinzengkehu1',
    title: '新建客户',
    name: 'Customer',
    // auth: { permissions: [PermissionEnum['客户管理.查看']] },
  },
  {
    icon: 'icon-xinzenghetong1',
    title: '新建合同',
    name: 'Contract',
    auth: { permissions: [PermissionEnum.合同管理] },
  },
  {
    icon: 'icon-xinzengyongzhang1',
    title: '新建用章',
    name: 'Seal',
    auth: { permissions: [PermissionEnum.用章管理] },
  },
  {
    icon: 'icon-xinzengcaiwu1',
    title: '新建财务',
    name: 'Finance',
    auth: { permissions: [PermissionEnum.财务管理] },
  },
  {
    icon: 'icon-xinzengshenpi2',
    title: '审批管理',
    name: 'Approve',
    auth: { permissions: [PermissionEnum.审批管理] },
  },
  {
    icon: 'icon-lichongjiansuo1',
    title: '利冲检索',
    name: 'Vertical',
    // auth: {
    //   permissions: [PermissionEnum.利冲检索],
    // },
  },
  {
    icon: 'icon-yonghushezhi1',
    title: '用户设置',
    name: 'Lawyer',
    auth: { permissions: [PermissionEnum.用户设置] },
  },
  {
    icon: 'icon-shenpishezhi1',
    title: '审批设置',
    name: 'ApproveConfig',
    auth: { permissions: [PermissionEnum.审批设置] },
  },
]

/**
 * 常用工具
 */
export const COMMON_TOOLS: CardType[] = [
  {
    icon: 'icon-legalCosts',
    title: '计算诉讼费',
    name: 'LegalFee',
  },
  {
    icon: 'icon-attorneyFee',
    title: '计算律师代理费',
    name: 'AttorneyFee',
  },
]

/**
 * 第三方平台
 */
export const THIRD_PLATFORM: Record<'province' | 'delegate', CardType[]> = {
  province: [
    {
      icon: 'icon-legalCosts',
      title: '数据报送中心',
      name: 'ProvinceAssociationDataCenter',
      auth: { permissions: [PermissionEnum['访问律协数据平台.数据报送中心']] },
    },
    {
      icon: 'icon-attorneyFee',
      title: '新闻投稿',
      name: 'Contribution',
      auth: { permissions: [PermissionEnum['访问律协数据平台.新闻投稿']] },
    },
    {
      icon: 'icon-attorneyFee',
      title: '问卷调查',
      name: 'Questionnaire',
      auth: { permissions: [PermissionEnum['访问律协数据平台.问卷调查']] },
    },
  ],
  delegate: [
    {
      icon: 'icon-legalCosts',
      title: '委托广场',
      name: 'DelegateSquare',
      auth: { permissions: [PermissionEnum.委托广场] },
    },
    {
      icon: 'icon-attorneyFee',
      title: '我发布的委托',
      name: 'PublishedDelegate',
      auth: { permissions: [PermissionEnum.委托广场] },
    },
    {
      icon: 'icon-attorneyFee',
      title: '我帮助的委托',
      name: 'AppliedDelegate',
      auth: { permissions: [PermissionEnum.委托广场] },
    },
  ],
}
