import { PermissionEnum, RoleEnum } from '@/enums'
import { getUserInfo } from '../get'

// 判断用户是否是指定的角色
export const hasRole = (roles: RoleEnum[], options?: { rule?: 'all' | 'any' }) => {
  const userinfo = getUserInfo()
  if (!userinfo) return false
  const { rule = 'any' } = options || {}
  return rule === 'all'
    ? roles.every((role) => (userinfo.role as unknown as RoleEnum[]).includes(role))
    : roles.includes(userinfo.role)
}

// 判断用户是否有指定的权限列表
export const hasPermission = (permissionsList: PermissionEnum[], options?: { rule?: 'all' | 'any' }) => {
  const userinfo = getUserInfo()
  if (!userinfo) return false
  const { rule = 'all' } = options || {}
  // 把枚举转为字符串判断是因为考虑未来权限可能也会支持字母类型的字符串或者说有大数字的情况所以用字符串会更好扩展,(至于为什么不在声明PermissionEnum的时候就把值声明为字符串是因为字符串不能自动推导枚举值)
  return rule === 'all'
    ? permissionsList.every((auth) => userinfo.authList.includes(auth.toString() as unknown as PermissionEnum))
    : permissionsList.some((auth) => userinfo.authList.includes(auth.toString() as unknown as PermissionEnum))
}

// 根据传入的角色列表和权限列表判断用户是否有权限
export const hasAuth = (auth?: AuthType) => {
  if (!auth) return true
  // 是数组则默认是角色
  if (Array.isArray(auth)) {
    return hasRole(auth)
  }
  if (typeof auth === 'function') {
    return auth()
  }
  if (Object.prototype.toString.call(auth) === '[object Object]') {
    const { roles, permissions } = auth
    if (!permissions && !roles) return true
    const { priority } = auth.options || {}
    const permissionsOptions = { rule: auth.options?.permissionsRule }
    const rolesOptions = { rule: auth.options?.rolesRule }
    // 根据优先级判断是否有权限, 如果未传入roles或permissions则认为有权限
    if (priority === 'all') {
      return (
        (!permissions || hasPermission(permissions, permissionsOptions)) && (!roles || hasRole(roles, rolesOptions))
      )
    }
    if (permissions) return hasPermission(permissions, permissionsOptions)
    if (roles) return hasRole(roles, rolesOptions)
    return false
  }
}
