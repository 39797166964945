import { UserState } from './state'

export const mutations = {
  // setToken: (state: UserState, token: string) => {
  //   state.token = token;
  // },
  setUserInfo: (state: UserState, userInfo: UserInfo) => {
    state.userInfo = userInfo
  },
  // setRoles: (state: UserState, roles) => {
  //   state.roles = roles
  // },
  // setUserInfo: (state: UserState, info) => {
  //   state.info = info;
  // }
}
