import { ReportTypeModel } from '@/types';
import { CollectionStatus, DataCollection } from '@/types/api/statistics/custom-form';
/**
 * @description 数据采集对象
 */
export const REPORT_OBJECT = {
  1: '全省地市律协',
  2: '全省律所',
};

/**
 * @description 发布对象全省律所
 */
export const REPORT_OBJECT_FIRM: ReportTypeModel[] = [
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_1',
    label: '律师业务情况统计数据采集表一',
    reportType: 12,
    collectionStatus: CollectionStatus.notGather,
  },
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_2',
    label: '律师业务情况统计数据采集表二',
    reportType: 13,
    collectionStatus: CollectionStatus.notGather,
  },
  {
    value: 'PL_POLITICS_DATA_COLLECTION',
    label: '律师参政议政统计表采集',
    reportType: 14,
    collectionStatus: CollectionStatus.notGather,
  },
  {
    value: 'PL_SERVICE_DATA_COLLECTION',
    label: '律师公益法律服务统计表采集',
    reportType: 11,
    collectionStatus: CollectionStatus.notGather,
  },
];
/**
 * @description 发布对象全省律所
 */
export const customCollection: ReportTypeModel[] = [
  {
    value: 'PL_BUSINESS_STATISTICS_DATA_COLLECTION_1',
    label: '自定义数据',
    reportType: 12,
    collectionStatus: CollectionStatus.notGather,
  },
];
/**
 * @description 数据提交方式（单条数据提交）表三、表六
 */
export const REPORT_SUBMIT_SINGLE = [3, 6];

/**
 * @description 表单拓展数据（附件、备注）的表头
 */
export const REPORT_EXTEND_COLUMNS = [
  {
    title: '单位名称',
    dataIndex: 'recipientName',
    width: 200,
  },
  {
    title: '备注',
    dataIndex: 'remark',
  },
  {
    title: '附件',
    dataIndex: 'fileList',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 150,
  },
];

export const REPORT_TYPE = {
  11: '律师公益法律服务统计表',
  12: '律师业务统计表（表一）',
  13: '律师业务统计表（表二）',
  14: '律师参政议政统计表',
};
