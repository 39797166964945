import { createApp } from 'vue'
import App from './App.vue'
import router, { setupRouter } from './router'
// import store from './store';
import { setupStore } from '@/store'
import { setupAntd, setupCustomComponents } from '@/plugins'
import '@/styles/theme/index.less'
import '@/styles/global.less'
import { setupGlobDirectives } from './directives'
const app = createApp(App)

// 注册全局自定义组件,如：<svg-icon />
setupCustomComponents(app)

setupGlobDirectives(app)
// 注册全局常用的ant-design-vue组件
setupAntd(app)
// 挂载vuex状态管理
setupStore(app)
// 挂载路由
setupRouter(app)
// 路由准备就绪后挂载APP实例
router.isReady().then(() => app.mount('#app'))
