// import { Storage } from '@/utils/localStorage';
// import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types';
import { ProjectScopeModel, ProjectReasonModel, TrialStageModel, ClientModel } from '@/types/dictionary';

export type DictionaryState = {
  projectScopeOptions: Array<ProjectScopeModel>;
  projectScopeMap: any;
  projectReasonOptions: Array<ProjectReasonModel>;
  trialStageOptions: Array<TrialStageModel>;
  clientOptions: Array<ClientModel>;
};

export const state: DictionaryState = {
  projectScopeOptions: [], // 业务范围
  projectReasonOptions: [], // 案由
  trialStageOptions: [], // 审理阶段
  clientOptions: [], // 委托方身份
  projectScopeMap: {}
};
