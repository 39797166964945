export const ENCRYPT_JSON = 'application/encrypt-json';

export const APPKEY = 'web_firm_manage_1.0.0';

export const AUTO_CANCEL_TOKEN_MESSAGE = '请求重复，自动取消';

export const BASE_URL = '/api'; // base地址
export const FIRM_PREFIX_URL = '/firm'; // 律所请求地址前缀
export const ASSOCIATION_PREFIX_URL = '/association'; // 律协请求地址前缀
export const COMMON_API_PREFIX = '/support'; // 公共服务地址前者
