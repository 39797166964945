import { ActionContext } from 'vuex';
import { DictionaryState } from './state';
import { getProjectScope, getProjectReason, getTrialStage, getPrincipalIdentity } from '@/api/dictionary';
// import { COOKIE_TOKEN, COOKIE_CURRENT_USER, COOKIE_MENUS } from '@/constants/http';
// import { Storage } from '@/utils/localStorage';
// import store from '@/store'
import { IStore } from '@/store/types';
// import { message as Message } from 'ant-design-vue';

export const actions = {
  /**
   * @description 获取案件类型（业务范围）
   * @returns
   */
  async getProjectScope({ commit }: ActionContext<DictionaryState, IStore>) {
    try {
      const data = await getProjectScope();
      if (data) {
        commit('setProjectScope', data);
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * @description 获取案由信息
   * @param projectScopeId 业务范围id
   * @returns
   */
  async getProjectReason({ commit }: ActionContext<DictionaryState, IStore>, projectScopeId: string) {
    try {
      const data = await getProjectReason(projectScopeId);
      if (data) {
        commit('setProjectReason', data);
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * @description 获取阶段信息
   * @param typeId 业务类型Id
   * @returns
   */
  async getTrialStage({ commit }: ActionContext<DictionaryState, IStore>, typeId: string) {
    try {
      const data = await getTrialStage(typeId);
      if (data) {
        commit('setTrialStage', data);
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * @description 获取委托方身份
   * @param trialStageId 审理阶段Id
   * @returns
   */
  async getClient({ commit }: ActionContext<DictionaryState, IStore>, trialStageId: string) {
    try {
      const data = await getPrincipalIdentity(trialStageId);
      if (data) {
        commit('setClient', data);
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // 登录
  // async login({ commit }: ActionContext<DictionaryState, IStore>, userInfo) {
  //   try {
  //     const data = await login(userInfo);
  //     // const { data, code, message } = response
  //     if (data) {
  //       // const ex = 7 * 24 * 60 * 60 * 1000
  //       Storage.set(COOKIE_TOKEN, data.tokenInfo.token, data.tokenInfo.expireTime);
  //       Storage.set(COOKIE_CURRENT_USER, data.userInfo, data.tokenInfo.expireTime);
  //       commit('setToken', data.tokenInfo.token);
  //       commit('setUserInfo', data.userInfo);
  //     }
  //     return Promise.resolve(data);
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },

  // // 登出
  // async logout({ commit }: ActionContext<DictionaryState, IStore>) {
  //   try {
  //     await logout();
  //     commit('setRoles', []);
  //     commit('setUserInfo', '');
  //     Storage.remove(COOKIE_TOKEN);
  //     Storage.remove(COOKIE_CURRENT_USER);
  //     Storage.remove(COOKIE_MENUS);
  //     Message.success('成功退出登录');
  //     return Promise.resolve();
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }
};
