import axios, { AxiosInstance } from 'axios'
import { APPKEY, BASE_URL, TOKEN_INFO } from '@/constants'
import { ZFResponse, IAxiosError, IAxiosResponse, IRequestConfig } from '@/types/http'
import { redirectToLogin } from '@/utils/router'
import { Storage } from '@/utils/localStorage'
import { message } from 'ant-design-vue'
// import { refreshToken } from '@/api/user';
// import { encryptAES, decryptAES } from "@/plugins/crepto";
export class HttpRequest {
  // 声明一个 Map 用于存储每个请求的标识 和 取消函数
  public static axiosIns: AxiosInstance // axios实例
  public requestPending = new Map()
  private axiosRequestConfig: IRequestConfig = {} // axios 配置
  private isErrorMsg = false // 防止多次显示返回的多个错误信息

  /* 多个请求发送至后台，且refreshToken均失效，其实前台已拦截过期时间，见上个块级注释，如果后台过期时间正确，也没必要再次拦截，以防万一 */
  private pendingResponse: Array<any> = [] // token过期，请求响应需要重新请求因token过期的请求
  private isRefreshResponseToken = false // 是否正在刷新token，防止多个token同时刷新

  public constructor() {
    this.axiosRequestConfig.timeout = 15000 // 全局超时时限
    // this.axiosRequestConfig.baseURL = process.env.NODE_ENV === 'production' ? `${process.env.VUE_APP_API_URL}${process.env.BASE_URL}` : '';
    this.axiosRequestConfig.baseURL = process.env.VUE_APP_API_URL + BASE_URL

    HttpRequest.axiosIns = axios.create(this.axiosRequestConfig) // 实例axios
    HttpRequest.axiosIns.interceptors.request.use(this.onRequest.bind(this)) // 请求拦截
    HttpRequest.axiosIns.interceptors.response.use(this.onResponseSuccess.bind(this), this.onResponseError.bind(this)) // 响应拦截
  }
  public get<R = any>(url: string, config?: IRequestConfig): Promise<R> {
    return HttpRequest.axiosIns.get(url, config)
  }

  public post<R = any>(url: string, data?: any, config?: IRequestConfig): Promise<R> {
    return HttpRequest.axiosIns.post(url, data, config)
  }

  public put<R = any>(url: string, data?: any, config?: IRequestConfig): Promise<R> {
    return HttpRequest.axiosIns.put(url, data, config)
  }

  public patch<R = any>(url: string, data?: any, config?: IRequestConfig): Promise<R> {
    return HttpRequest.axiosIns.patch(url, data, config)
  }

  public delete<R = any>(url: string, config?: IRequestConfig): Promise<R> {
    return HttpRequest.axiosIns.delete(url, config)
  }

  public head<R = any>(url: string, config?: IRequestConfig): Promise<R> {
    return HttpRequest.axiosIns.head(url, config)
  }
  /**
   * 添加请求
   * @param {Object} config
   */
  private addPending = (config: IRequestConfig) => {
    // 获构建请求唯一标识
    const url = [config.method, config.url].join('&')
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!this.requestPending.has(url)) {
          // 如果 pending 中不存在当前请求，则添加进去
          this.requestPending.set(url, cancel)
        }
      })
  }

  /**
   * 移除请求
   * @param {Object} config
   */
  private removePending = (config: IRequestConfig) => {
    const url = [config.method, config.url].join('&')
    if (this.requestPending.has(url)) {
      // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
      const cancel = this.requestPending.get(url)
      cancel(url)
      this.requestPending.delete(url)
    }
  }

  /**
   * 三秒之内的多个接口错误信息，只显示一个错误信息
   */
  private messageError = (msg?) => {
    if (!this.isErrorMsg) {
      this.isErrorMsg = true
      message.error(msg || '网络错误')
      setTimeout(() => {
        this.isErrorMsg = false
      }, 3000)
    }
  }

  /**
   * axios 请求拦截封装
   * @param request
   */
  private onRequest(request: IRequestConfig): IRequestConfig | Promise<IRequestConfig> {
    if (request.isCancel) {
      // 在请求开始前，对之前的请求做检查取消操作
      this.removePending(request)
      // 添加请求到请求队列
      this.addPending(request)
    }
    if (!request.headers) {
      request.headers = {}
    }
    request.headers['appkey'] = APPKEY
    // 始终用最新的token
    const token = Storage.get(TOKEN_INFO)?.token
    if (token) {
      request.headers['token'] = token
    }
    // 如果有数据需要加密
    // if (request.isCrepto) {
    //   const data = request.data || {}; // 只能是对象，不可能存在只传一个字符串这种
    //   request.headers["content-type"] = ENCRYPT_JSON;
    //   request.data = encryptAES(JSON.stringify(data));
    // }

    // 需要额外加header的接口
    // if (request.addHeaders && Object.keys(request.addHeaders).length > 0) {
    //   for (const key in request.addHeaders) {
    //     if (Object.prototype.hasOwnProperty.call(request.addHeaders, key)) {
    //       request.headers[key] = request.addHeaders[key];
    //     }
    //   }
    // }
    return request
  }

  /**
   * 刷新token
   * @param config
   */
  private refreshTokenFunc(config: IRequestConfig) {
    const refreshToken = Storage.get(TOKEN_INFO)?.refreshToken
    refreshToken(refreshToken)
      .then((res) => {
        // if (res.data && res.data.code === 0) {
        // if (res.data.data) {
        // updateTokenInfo(res.data.data);
        // TODO: 更新token相关信息
        Storage.set(TOKEN_INFO, res)
        if (!config.headers) {
          config.headers = {}
        }
        config.headers['token'] = Storage.get(TOKEN_INFO)?.token
        this.pendingResponse.forEach((cb) => cb(res.token))
        this.pendingResponse = []
        this.isRefreshResponseToken = false
        return HttpRequest.axiosIns(config)
        // } else {
        // 如果refreshToken返回的data为空，则均定位到登录界面
        // redirectToLogin();
        // }
        // }
        // this.isRefreshResponseToken = false;
      })
      .catch(() => {
        // 接口失败
        this.isRefreshResponseToken = false
        redirectToLogin()
      })
  }

  /**
   * axios 响应成功拦截封装
   * 响应拦截思路：
   * @param response
   */
  private onResponseSuccess(response: IAxiosResponse): Promise<IAxiosResponse> | any {
    const { config, data: res } = response
    if (config.isCancel) {
      this.removePending(config) // 在请求结束后，移除本次请求
    }

    // 接口返回200
    const { code } = res
    // if (code === 0) {
    //   return Promise.resolve(res.data);
    // }
    switch (code) {
      case undefined:
        return Promise.resolve(res)
      case 0:
        return Promise.resolve(res.data)
      // case 5004: {
      //   if (!this.isRefreshResponseToken) {
      //     // 开始刷新token
      //     this.isRefreshResponseToken = true;
      //     return this.refreshTokenFunc(config);
      //   } else {
      //     return new Promise(resolve => {
      //       this.pendingResponse.push((token: string) => {
      //         config.headers.token = token;
      //         resolve(HttpRequest.axiosIns(config));
      //       });
      //     });
      //   }
      // }
      // 刷新token失效
      case 5004:
      case 5005:
      case 5003:
      case 5006: // 您的账号已在其他地方登录，请确认账号安全
      case 5007:
        this.messageError(res.message)
        setTimeout(() => {
          redirectToLogin()
        }, 200)
        break
      case 25002:
        // 名片不存在提示, 啥都不做
        break
      default:
        this.messageError(res.message)
        break
    }
    return Promise.reject()
  }

  /**
   * 根据content-type解密response
   * @param response
   */
  // decryptResponse(response: AxiosResponse): AxiosResponse {
  //   const responseData = response.data;
  //   if (response.headers["content-type"] === ENCRYPT_JSON) {
  //     let tempData = "";
  //     if (responseData && responseData.data && typeof responseData.data === "string") {
  //       tempData = decryptAES(responseData.data);
  //       response.data.data = tempData;
  //     }
  //   }
  //   return response.data;
  // }

  /**
   * axios 响应错误拦截封装
   * @param error
   */
  private onResponseError(error: IAxiosError) {
    const { config } = error
    if (axios.isCancel(error)) {
      this.messageError()
      this.removePending(config || {}) // 从pendingRequest对象中移除请求
    } else {
      // TODO 根据实际接口操作
      // 三秒之内的多个接口错误信息，只显示一个错误信息
      this.messageError()
    }
    return Promise.reject(error)
  }
}

export const http = new HttpRequest()
export default http
