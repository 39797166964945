/**
 * @description 通告页面类型
 */
export enum NoticePageEnum {
  PAGE_LIST = 'list', // 通告列表页
  PAGE_LOOK = 'look' // 查看页
}

/**
 * @description 通知阅读情况
 */
export enum NoticeReadStatusEnum {
  NOTICE_READ = 1, // 已阅读
  NOTICE_UNREAD = 2 // 未读
}

/**
 * @description 是否通知下级律协
 */
export enum NoticeAssociationTypeEnum {
  IS_LOWER_ASSOCIATION = 1, // 是
  NO_LOWER_ASSOCIATION = 2 // 否
}

/**
 * @description 通知律所
 */
export enum NoticeFirmTypeEnum {
  IS_FIRM = 1, // 是
  NO_FIRM = 2 // 否
}
