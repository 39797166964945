import { http } from '@/service';
import { ASSOCIATION_PREFIX_URL } from '@/constants';
import { NoticePageParamsModel } from '@/types';

/**
 * @description: 2103005 查看通知公告详情(接收者 【注意接口调用】)
 * @param bulletinId 公告id
 */
export const getNoticeDetail = (bulletinId: string): Promise<any> => {
  return http.post(`${ASSOCIATION_PREFIX_URL}/bulletin/read`, { bulletinId });
};

/**
 * @description: 2103006 获取接收到的通知公告列表
 * @param title 检索标题
 * @param size 每页显示条数，默认 10
 * @param current 当前页
 */
export const getNoticePage = (params: NoticePageParamsModel): Promise<any> => {
  return http.post(`${ASSOCIATION_PREFIX_URL}/bulletin/bulletinPage`, params);
};
