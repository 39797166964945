import router from '@/router';
import { Storage } from '@/utils/localStorage';
import { ExceptionEnum, NoticePageEnum, ProvincePageEnum } from '@/enums';

/**
 * 重定向到登录页
 */
export const redirectToLogin = (url: string = router.currentRoute.value.fullPath) => {
  Storage.clear();
  // TODO: 重置store
  router.replace(`/login?${url ? 'redirectUrl=' + url : ''}`);
};

/**
 * 导航到异常页
 */
export const navigateToException = (code: ExceptionEnum, { replace }: { replace?: boolean } = { replace: true }) => {
  replace ? router.replace(`/exception/${code}`) : router.push(`/exception/${code}`);;
};

/**
 * 重定向到首页
 */
export const redirectToHome = () => {
  router.replace('/home');
};

/**
 * @description 跳转到案件详情页
 */
export const toCaseDetail = (id: string | undefined) => {
  router.push({
    name: 'CaseIndex',
    query: {
      id
    },
    params: {
      type: 'detail'
    }
  });
};

/**
 * @description 跳转到案件添加页
 */
export const toCaseAdd = scopeId => {
  router.push({
    name: 'CaseIndex',
    params: {
      type: 'add'
    },
    query: {
      scopeId
    }
  });
};

/**
 * @description 跳转到案件列表页
 */
export const toCaseList = () => {
  router.push({
    name: 'CaseIndex'
  });
};

/**
 * @description 跳转到利冲检索列表页
 * @param isBlank // 是否打开新窗口
 */
export const toVerticalList = (isBlank = true) => {
  if (isBlank) {
    const route = router.resolve({
      name: 'VerticalIndex',
      params: {
        type: 'list'
      }
      // query: {
      //   searchVal
      // }
    });
    window.open(route.href, '_blank');
  } else {
    router.push({
      name: 'VerticalIndex',
      params: {
        type: 'list'
      }
    });
  }
};

/**
 * @description 跳转到通知查看页
 * @param bulletinId 通知id
 */
export const toNoticeLook = (bulletinId: string) => {
  router.push({
    name: 'Notice',
    params: {
      page: NoticePageEnum.PAGE_LOOK
    },
    query: {
      bulletinId
    }
  });
};

/**
 * @description 跳转到通知列表页
 */
export const toNoticeList = () => {
  router.push({
    name: 'Notice'
  });
};

/**
 * @description 跳转到某个统计表格的列表页（时间轴）
 */
export const toReportTimeList = (reportType?: number) => {
  router.push({
    name: 'ProvinceAssociation',
    params: {
      page: ProvincePageEnum.PAGE_REPORT_TIME_LIST
    },
    query: {
      reportType
    }
  });
};

/**
 * @description 跳转到查看表页面（数据报送）
 * @param reportType 表类型（14种）
 */
export const toReportLook = (reportType?: number, id?: string | number) => {
  router.push({
    name: 'ProvinceAssociation',
    params: {
      page: ProvincePageEnum.PAGE_REPORT_LOOK
    },
    query: {
      reportType, id
    }
  });
};

/**
 * @description 跳转到编辑页面（数据报送）
 * @param reportType 表类型（14种）
 */
export const toReportEdit = (reportType?: number, id?: string | number) => {
  router.push({
    name: 'ProvinceAssociation',
    params: {
      page: ProvincePageEnum.PAGE_REPORT_EDIT
    },
    query: {
      reportType, id
    }
  });
};
