export const TOKEN_INFO = 'Token-Info'; // 用户token
export const USER_INFO = 'user-info'; // 用户登录信息
export const DEFAULT_EXPIRED_TIME = 7; // cookie默认存储时间
export const VERTICAL_SEARCH = 'vertical-search'; // 检索关键字
// export const REPORT_TYPE = 'report-type'; // 数据采集表格权限
export const EDIT_COLLECTION = 'edit-collection'; // 采集表修改（采集）
export const EXTEND_DATA = 'extend-data'; // 扩展信息
export enum LocalStorageKeys {
  REGION_DATA = 'region-data', //地区选择器数据
  IS_MENU_COLLASPED = 'is-menu-collasped', //菜单是否折叠
  GLOBAL_STATE = 'global-state', //全局状态
}
