import { PermissionEnum, RoleEnum } from '@/enums';
import { hasAuth, hasPermission, hasRole } from '@/utils';
import { App, Directive, DirectiveBinding } from 'vue';


function checkAuth(el: HTMLElement, binding: DirectiveBinding<AuthType>) {
  if (!el.parentNode) return;
  const { value } = binding;
  !hasAuth(value) && el.parentNode.removeChild(el);
}
const authDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkAuth(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkAuth(el, binding);
  },
}
export function setupAuthDirective(app: App) {
  app.directive('auth', authDirective);
}
export default authDirective;
