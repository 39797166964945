/**
 * customer
 */
//  客户类型
export const customerTypeOptions = [
  {
    value: 1,
    label: '公司'
  },
  {
    value: 2,
    label: '政府'
  },
  {
    value: 3,
    label: '个人'
  }
];
export const customerType = {
  1: '公司',
  2: '政府',
  3: '个人'
};
export const customerTypeIcon = {
  1: 'icon-gongsi1',
  2: 'icon-zhengfu1',
  3: 'icon-geren1'
};

// 客户状态
export const customerStatusOptions = [
  {
    value: 1,
    label: '有意向'
  },
  {
    value: 2,
    label: '已签约'
  }
];
export const customerStatus = {
  1: '有意向',
  2: '已签约'
};

// 证件类型
export const certificateType = {
  1: '机构代码',
  2: '身份证号'
};

/**
 * 用户管理权限
 */

// 客户管理
export const customerAuthOptions = [
  {
    value: 'CUSTOMER_MANAGE_GLOBAL_VIEW',
    label: '查看客户'
  },
  {
    value: 'CUSTOMER_MANAGE_GLOBAL_EDIT',
    label: '编辑客户'
  },
  {
    value: 'CUSTOMER_MANAGE_GLOBAL_STOP',
    label: '删除客户'
  }
];

// 案件管理
export const caseAuthOptions = [
  {
    value: 'CASE_MANAGE_GLOBAL_VIEW',
    label: '查看案件'
  }
];

// 合同管理
export const contractAuthOptions = [
  {
    value: 'CONTRACT_MANAGE_GLOBAL_VIEW',
    label: '查看合同'
  }
];

// 财务管理
export const financeAuthOptions = [
  {
    value: 'FINANCE_MANAGE_GLOBAL_VIEW',
    label: '查看财务明细'
  }
];

// 用章管理
export const sealAuthOptions = [
  {
    value: 'SEAL_MANAGE_GLOBAL_VIEW',
    label: '查看用章明细'
  }
];

/**
 * 审批管理
 */
// 审批类型
export const approveType = {
  1: '合同',
  2: '用章',
  3: '收款',
  4: '支出',
  5: '案件'
};

// 审批图标
export const approveTypeIcon = {
  1: 'icon-hetong3',
  2: 'icon-yongzhang3',
  3: 'icon-shoukuan2',
  4: 'icon-fukuan1',
  5: 'icon-anjian1'
};

// 审批状态
export const approveStatus = {
  1: '审核中',
  2: '已通过',
  3: '已驳回'
};

export const approveStatusOptions = [
  {
    label: '已通过',
    value: 2
  },
  {
    label: '已拒绝',
    value: 3
  },
  {
    label: '审核中',
    value: 1
  }
];

/**
 * 财务
 */
// 收款、支出类型
export const financeOriginOptions = [
  { value: 1, label: '合同' },
  { value: 2, label: '其他' }
];

export const financeOrigin = {
  1: '合同',
  2: '其他'
};

// 收款、支出方式
export const financePayType = {
  1: '银行卡',
  2: '支付宝 ',
  3: '微信转账',
  4: '现金支付'
};

export const financePayTypeOptions = [
  {
    value: 1,
    label: '银行卡'
  },
  {
    value: 2,
    label: '支付宝'
  },
  {
    value: 3,
    label: '微信转账'
  },
  {
    value: 4,
    label: '现金支付'
  }
];
