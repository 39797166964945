export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean },
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
  const feature: string[] = []

  noopener && feature.push('noopener=yes')
  noreferrer && feature.push('noreferrer=yes')

  window.open(url, target, feature.join(','))
}

/**
 * 从数组里获取由指定键名与值名组成的对象
 * @param arr 指定数组
 * @param keyProp 要从数组每一项里获取的键名
 * @param valueProp 要从数组每一项里获取的值的键名
 * @returns Record<K, V>
 */
export const getKeyValueFromArr = <T extends any[], K extends keyof T[number], V extends keyof Omit<T[number], K>>(
  arr: T,
  keyProp: K,
  valueProp: V,
): Record<K, T[number][V]> => {
  return arr.reduce<ReturnType<typeof getKeyValueFromArr>>((acc, item: any) => {
    acc[item[keyProp]] = item[valueProp]
    return acc
  }, {} as ReturnType<typeof getKeyValueFromArr>)
}

export const throttle = (fn: Fn, { interval }: { interval: number }) => {
  let prevTime = 0
  return function (this: any, ...arg: any[]) {
    const currentTime = Date.now()
    if (currentTime - prevTime >= interval) {
      fn.apply(this, arg)
      prevTime = currentTime
    }
  }
}

/**
 *@description 生成指定长度的随机数
 * @param length 随机数长度
 */
export function generateRandomStr(length: number) {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i)
    //根据需要的长度做循环
    //先对字符串集合str随机排列，再随机输出拼接
    result += str[Math.floor(Math.random() * str.length)]
  return result
}

/**
 *@description 生成文件名
 *@param type 文件类型
 */
export function generateFileName(file: File) {
  // 如果上传文件的后缀名包含. 则取.后面的字符作为文件后缀
  const fileNameArr = file.name.includes('.') && file.name.split('.')
  let fileName = Date.now() + generateRandomStr(6)
  if (fileNameArr) {
    const fileSuffix = fileNameArr[fileNameArr.length - 1]
    fileName += '.' + fileSuffix
  }
  return fileName
}

/**
 * value是否属于tree的子节点
 * @param treeData 树形结构数据
 * @param prop 需要用来与value比对的treeData内的键名
 * @param value 用来与子节点判断的值
 */

export const isBelongToTree = <T extends TreeStructure, K extends ExtractStringKey<T, keyof T[number]>>(
  treeData: T,
  prop: K,
  value: T[K],
): boolean => {
  return treeData.some((item) => {
    if (item[prop] === value) {
      return true
    } else if (item.children?.length) {
      return isBelongToTree(item.children, prop, value)
    }
    return false
  })
}

/**
 * 从treeData获取所有父级
 * @param value 用来与后代判断的值
 * @param prop 需要用来与value比对的treeData内的键名
 * @param treeData 树形结构数据
 */
//TODO: prop改为keyof T以获得类型提示
export const getParentsArrFromTreeData = <T extends TreeStructure = TreeStructure>(
  treeData: T,
  prop: string,
  value: ValueType,
) => {
  //FIXME: 缺乏类型
  let result: any[] = []
  treeData.forEach((item) => {
    // if (item[prop] === value) {
    //   console.log("🚀 ~ value", value)
    //   result.push(item);
    // }
    if (item.children?.length) {
      if (isBelongToTree(item.children, prop, value)) {
        result.push(item)
      }
      result.push(...getParentsArrFromTreeData(item.children, prop, value))
    }
  })
  return result
}

export const sleep = (delay: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, delay)
  })
}
