/**
 *  created by lili
 *  Date: 2021/12/21
 *  Time: 14:41
 *  Version: 1.0
 *  For:
 */
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'; // import locale
import calendar from 'dayjs/plugin/calendar'; // import plugin
import isToday from 'dayjs/plugin/isToday'; // 是否今天
import isTomorrow from 'dayjs/plugin/isTomorrow'; // 是否明天
import isYesterday from 'dayjs/plugin/isYesterday'; // 是否昨天
dayjs.locale('zh-cn'); // use locale
dayjs.extend(calendar);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

/**
 * @description 日期转换
 * @param date 日期格式的时间
 * @param format 日期格式
 */
export const formatDate = (date, format?) => {
  const baseFormat = 'YYYY-MM-DD HH:mm:ss';
  if (!date) return;
  return dayjs(date).format(format || baseFormat);
};

/**
 *  格式化时间为今明天，
 */
export const formatCnDate = (date) => {
  if (dayjs(date).isToday()) return '今天';
  if (dayjs(date).isTomorrow()) return '明天';
  if (dayjs(date).isYesterday()) return '昨天';
  // 是否为本周
  if (isSameWeek(date)) {
    return dateWeek(date, 'week');
  }
  // 是否为上周
  if (isLastWeek(date)) {
    return dateWeek(date, 'lastWeek');
  }
  // 是否为下周
  if (isNextWeek(date)) {
    return dateWeek(date, 'nextWeek');
  }
  // 判断时间是否是今年
  if (isThisYear(date)) {
    return dayjs(date).format('MM/DD');
  }
  return dayjs(date).format('YYYY/MM/DD');
};

// 判断时间是否是今年
export const isThisYear = (val) => {
  if (val) {
    return dayjs().year() === new Date(val).getFullYear();
  }
};
/**
 * isSameWeek 是否为本周
 */
export const isSameWeek = (inDate) => {
  // inDate 是一个date对象
  const inDateStr = new Date(inDate).toLocaleDateString(); // 获取如YYYY/MM/DD的日期
  const nowDate = new Date();
  const nowTime = nowDate.getTime();
  const nowDay = nowDate.getDay();
  for (let i = 1; i < 8; i++) {
    if (inDateStr === new Date(nowTime + (i - nowDay) * 24 * 3600 * 1000).toLocaleDateString()) return true;
  }
  return false;
};

/**
 * isLastWeek判断是不是上周
 */
function isLastWeek(temp) {
  const date = new Date();
  const week = date.getDay() === 0 ? 7 : date.getDay(); // 把周日作为一周最后一天
  const mon = time2date(0, true, 1 - week).date;
  const monStamp = new Date(mon).getTime() - 8 * 60 * 60 * 1000; // 本周周一零点时间，减8 * 60 * 60 * 1000，减掉8小时北京时间
  const lastMonStamp = new Date(mon).getTime() - (8 + 24 * 7) * 60 * 60 * 1000; // 上周周一零点时间，减8 * 60 * 60 * 1000，减掉8小时北京时间
  const tempDate = new Date(temp).getTime() - 8 * 60 * 60 * 1000; // 当期日期
  if (lastMonStamp <= tempDate && tempDate < monStamp) {
    return true; // 时间戳在[上周周一时间戳,本周周一时间戳)的区间内，属于上周
  } else {
    return false; // 不属于上周
  }
}

/**
 * isNextWeek判断是不是下周
 */
function isNextWeek(temp) {
  const date = new Date();
  const week = date.getDay() === 0 ? 7 : date.getDay(); // 把周日作为一周最后一天
  const mon = time2date(0, true, 7 - week).date; // 获取到本周日
  const monStamp = new Date(mon).getTime() - 8 * 60 * 60 * 1000; // 本周天零点时间，减8 * 60 * 60 * 1000，减掉8小时北京时间
  const nextMonStamp = new Date(mon).getTime() + (8 + 24 * 7) * 60 * 60 * 1000; // 下周天零点时间，减8 * 60 * 60 * 1000，减掉8小时北京时间
  const tempDate = new Date(temp).getTime() - 8 * 60 * 60 * 1000; // 当前日期
  if (tempDate <= nextMonStamp && tempDate > monStamp) {
    return true; // 时间戳在[本周天时间戳,下周天时间戳)的区间内，属于下周
  } else {
    return false; // 不属于下周
  }
}

// 计算前几天是几号的转换函数
function time2date(temp: number, isWeek: boolean, time: number) {
  let tempTime;
  if (isWeek) {
    tempTime = new Date().getTime() + temp * 24 * 60 * 60 * 1000;
  } else {
    tempTime = time;
  }
  const date = new Date(tempTime);
  const yy = date.getFullYear();
  const mm = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
  const dd = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
  const hh = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
  const week = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  const weekday = week[date.getDay()];
  return { date: yy + '-' + mm + '-' + dd, week: weekday, hh: hh };
}

// 周几格式化
const dateWeek = (date, type) => {
  let str = type === 'lastWeek' ? '上周' : type === 'nextWeek' ? '下周' : '本周';
  const week = new Date(date).getDay();
  switch (week) {
    case 0:
      str += '天';
      break;
    case 1:
      str += '一';
      break;
    case 2:
      str += '二';
      break;
    case 3:
      str += '三';
      break;
    case 4:
      str += '四';
      break;
    case 5:
      str += '五';
      break;
    case 6:
      str += '六';
      break;
  }
  return str;
};

// 将秒转化为时分秒
export const formatSecToDate = (sec) => {
  if (!sec) {
    return '00:00';
  }
  let min: number | string = Math.floor(sec % 3600) / 60;
  let hour: number | string = Math.floor(sec / 3600);

  min = min < 10 ? '0' + min : min;
  hour = hour < 10 ? '0' + hour : hour;

  return hour + ':' + min;
};
// export const changeQuickDate = val => {
//   let daterage: Moment[] = [];
//   const date: Moment = moment(); // 当前日期
//   switch (val) {
//     case 'day':
//       daterage = [date, date];
//       break;
//     case 'week':
//       {
//         const start = moment().startOf('week').add('day');
//         daterage = [start, date];
//       }
//       break;
//     case 'month':
//       {
//         const month = moment().startOf('month');
//         daterage = [month, date];
//       }
//       break;
//     case 'quarter':
//       {
//         // 本季度
//         const start = moment().startOf('quarter'); // 开始
//         // const end = moment().endOf('quarter'); // 结束
//         daterage = [start, date];
//       }
//       break;
//     case 'year':
//       {
//         // 本年
//         const start = moment().startOf('year');
//         // const end = date.endOf('year');
//         daterage = [start, date];
//       }
//       break;
//   }
//   return daterage;
// };

/**
 * @description 判断时间是否截止
 */
export const isTimeEnd = (time) => {
  const nowTime = dayjs().valueOf(); // 当前时间戳
  const endTime = dayjs(time).valueOf(); // 截止时间的时间戳
  return endTime <= nowTime;
};
