export enum PermissionEnum {
  '案件管理' = 1,
  '客户管理',
  '利冲检索',
  '合同管理',
  '用章管理',
  '财务管理',
  '审批管理',
  '访问律协数据平台',
  '个人名片',
  '行业通讯录',
  '异地查档协助中心',
  '系统设置',
  '委托广场',
  '我发布的委托',
  '通知公告',
  '我帮助的委托',
  '用户设置',
  '审批设置',
  '客户管理.查看',
  '客户管理.删除',
  '客户管理.编辑',
  '访问律协数据平台.数据报送中心',
  '访问律协数据平台.新闻投稿',
  '访问律协数据平台.问卷调查',
}

export enum RoleEnum {
  admin = 1,
  member,
}
