/**
 * @description: 审批类型/财务下拉类型（除5:案件）
 */
export enum ApproveTypeEnum {
  CONTRACT = 1, // 合同
  SEAL = 2, // 用章
  COLLECTION = 3, // 收款
  EXPENDITURE = 4, // 支出
  CASE = 5, // 案件
}

/**
 * @description: 审批管理
 */
export enum MyApproveManageEnum {
  ME_APPROVE = 1, // 我提交的审批
  TO_ME_APPROVE = 2, // 待我审批
  ME_ALREADY_APPROVE = 3, // 我已审批
}

/**
 * 审批状态
 */
export enum ApproveStatusEnum {
  APPROVE_WAIT = 1, // 审核中
  APPROVE_SUCCESS = 2, // 通过
  APPROVE_REJECT = 3, // 驳回
}

/**
 * @description 获取财务下拉列表 含：合同、用章、付款、支出
 */
// 审批范围
export enum FDApproveScopeEnum {
  APPROVE_EXCEPT_REJECT = 1, // 除已拒绝外全部检索
  APPROVE_SUCCESS = 2, // 通过
}

// 检索范围
export enum FDSearchScopeEnum {
  SEARCH_ALL = 1, // 全部检索
  SEARCH_AUTH = 2, // 根据权限检索
  SEARCH_CURRENT_USER = 3, // 3：只查询当前登录用户
}

/**
 * @description 案件
 */

export enum CaseStatusEnum {
  CASE_BUILD = 1, // 新增案件
  CASE_APPROVE = 2, // 立案审批中
  CASE_DOING = 3, // 办理中
  CASE_FILE = 4, // 归档
  CASE_APPROVE_DELETE = 5, // 删除审批中
  CASE_DELETE = 6, // 已删除
}

// 案件进展
export enum CaseProcessEnum {
  CASE_BUILD = 1, // 新增案件
  CASE_DOING = 2, // 办理中
  CASE_FILE = 3, // 归档
}

// 案件tabs
export enum CaseTabsEnum {
  CASE_HISTORY = 1, // 跟进日志
  CASE_CONTRACT = 2, // 关联合同
  CASE_SEAL = 3, // 关联用章
  CASE_FINANCE = 4, // 关联财务
}

// 省市区划分
export enum XZQLevelEnum {
  PROVINCE = 1, // 省
  CITY = 2, // 地级市
  REGION = 3, // 区县
}
