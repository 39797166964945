/**
 * @description: 客户类型
 */
export enum CustomerTypeEnum {
  CUSTOMER_COMPANY = 1, // 公司
  CUSTOMER_GOVERNMENT = 2, // 政府
  CUSTOMER_PERSONAL = 3 // 个人
}

/**
 * @description: 证件类型
 */
export enum DocumentTypeEnum {
  ORGANIZATIO_CODE = 1, // 机构代码
  ID_NUMBER = 2 // 身份证号
}

/**
 * @description: 客户状态
 */
export enum CustomerStateEnum {
  CUSTOMER_STATE_INTENTION = 1, // 有意向
  CUSTOMER_STATE_SIGNED = 2 // 已签约
}
