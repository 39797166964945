import { ActionContext } from 'vuex';
import { INoticeState } from './state';
import { getNoticePage } from '@/api/notice';
import { IStore } from '@/store/types';
import { NoticePageParamsModel } from '@/types';

export const actions = {
  // 登录
  async getNoticeAction({ commit }: ActionContext<INoticeState, IStore>, pageParams: NoticePageParamsModel) {
    try {
      const data = await getNoticePage(pageParams);
      commit('setNoticeMutation', data);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
