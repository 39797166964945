import { RouteRecordRaw } from 'vue-router'
import { AppState } from './state'

export const mutations = {
  setRoutes(state: AppState, routes) {
    // 设置动态路由
    // state.menus = routes;
  },
  setMenus(state: AppState, routes: RouteRecordRaw[]) {
    state.menus = routes
  },
  setKeepAliveComponents(state: AppState, compNames: string[]) {
    // 设置需要缓存的组件
    state.keepAliveComponents = compNames
  },
}
